import { COBALT_COLORS, ORANGE_COLORS } from '@junglescout/styles-library'

export const COBALT_APP_TYPE = 'cobalt'
export const DEFAULT_APP_TYPE = 'default'

export const DEFAULT_THEME = {
  appType: DEFAULT_APP_TYPE,
  colors: ORANGE_COLORS
}

export const COBALT_THEME = {
  appType: COBALT_APP_TYPE,
  colors: COBALT_COLORS
}
