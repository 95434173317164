export const ZIPCODE_EXCLUSIONS = ['HK']

export const ZIPCODES = {
  AC: '^(ASCN 1ZZ)$',
  AD: '^(?:AD)*(\\d{3})$',
  AE: 0,
  AF: '^(\\d{4})$',
  AG: '^(\\d{5})$',
  AI: '^(AI-2640)$',
  AL: '^(\\d{4})$',
  AM: '^(\\d{4})$',
  AN: 0,
  AO: 0,
  AQ: '^(7151)$',
  AR: '^([A-Z]\\d{4}[A-Z]{3})|([A-Z]?\\d{4})$',
  AS: '^(\\d{5})$',
  AT: '^(\\d{4})$',
  AU: '^(\\d{4})$',
  AW: '^(0000 AW)$',
  AX: '^(\\d{5})$',
  AZ: '^(AZ) (\\d{4})$',
  BA: '^(\\d{5})$',
  BB: '^(?:BB)*(\\d{5})$',
  BD: '^(\\d{4})$',
  BE: '^(\\d{4})$',
  BF: 0,
  BG: '^(\\d{4})$',
  BH: '^(\\d{3}\\d?)$',
  BI: 0,
  BJ: 0,
  BL: '^(\\d{5})$',
  BM: '^([A-Z]{2} \\d{2})$',
  BN: '^([A-Z]{2}\\d{4})$',
  BO: 0,
  BQ: '^(0000 BQ)$',
  BR: '^[0-9]{5}-[0-9]{3}$',
  BS: 0,
  BT: '^(\\d{5})$',
  BV: 0,
  BW: 0,
  BY: '^(\\d{6})$',
  BZ: 0,
  CA: '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$',
  CC: '^(6799 W3)$',
  CD: 0,
  CF: 0,
  CG: 0,
  CH: '^(\\d{4})$',
  CI: 0,
  CK: 0,
  CL: '^(\\d{7})$',
  CM: 0,
  CN: '^(\\d{6})$',
  CO: '^(\\d{6})$',
  CR: '^(\\d{5})$',
  CU: '^(?:CP)*(\\d{5})$',
  CV: '^(\\d{4})$',
  CW: '^(0000 CW)$',
  CX: '^(6978)$',
  CY: '^(\\d{4})$',
  CZ: '^[0-9]{3} [0-9]{2}$',
  DE: '^(\\d{5})$',
  DJ: 0,
  DK: '^(\\d{4})$',
  DM: 0,
  DO: '^(\\d{5})$',
  DZ: '^(\\d{5})$',
  EC: '^(\\d{6})$',
  EE: '^(\\d{5})$',
  EG: '^(\\d{5})$',
  EH: '^(\\d{5})$',
  ER: 0,
  ES: '^(\\d{5})$',
  ET: '^(\\d{4})$',
  FI: '^(?:FI)*(\\d{5})$',
  FJ: 0,
  FK: '^(FIQQ 1ZZ)$',
  FM: '^(\\d{5})$',
  FO: '^(?:FO)*(\\d{3})$',
  FR: '^(\\d{5})$',
  GA: 0,
  GB:
    '^(((([A-PR-UWYZ][0-9][0-9A-HJKS-UW]?)|([A-PR-UWYZ][A-HK-Y][0-9][0-9ABEHMNPRV-Y]?))\\s{0,2}[0-9]([ABD-HJLNP-UW-Z]{2}))|(GIR\\s{0,2}0AA))$',
  GD: 0,
  GE: '^(\\d{4})$',
  GF: '^((973)\\d{2})$',
  GG:
    '((GY)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2})',
  GH: 0,
  GI: '^(GX11 1AA)$',
  GL: '^(\\d{4})$',
  GM: 0,
  GN: '^(\\d{3})$',
  GP: '^((971)[0-9][0-0])$',
  GQ: 0,
  GR: '^(\\d{3}) \\d{2}$',
  GS: '^(SIQQ 1ZZ)$',
  GT: '^(\\d{5})$',
  GU: '^((969)[1-3][0-2])$',
  GW: '^(\\d{4})$',
  GY: 0,
  HK: '^(999077)$',
  HM: '^(7151)$',
  HN: '^(\\d{5})$',
  HR: '^(?:HR)*(\\d{5})$',
  HT: '^(?:HT)*(\\d{4})$',
  HU: '^(\\d{4})$',
  ID: '^(\\d{5})$',
  IE: 0,
  IL: '^(\\d{5})$',
  IM:
    '((IM)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2})',
  IN: '^(\\d{6})$',
  IO: '^(BB9D 1ZZ)$',
  IQ: '^(\\d{5})$',
  IR: '^\\d{5}([\\-]\\d{5})?$',
  IS: '^(\\d{3})$',
  IT: '^(\\d{5})$',
  JE: '(JE)([0-9]\\d{1})',
  JM: '^((JM)[A-Z]{3,3}\\d{2,2})$',
  JO: '^(\\d{5})$',
  JP: '^(\\d{3}-\\d{4})$',
  KE: '^(\\d{5})$',
  KG: '^(\\d{6})$',
  KH: '^(\\d{5})$',
  KI: 0,
  KM: 0,
  KN: 0,
  KP: 0,
  KR: '^(\\d{5})$',
  KW: '^(\\d{5})$',
  KY: '^[K][Y][0-9]{1}[-]([0-9]){4}$',
  KZ: '^(\\d{6})$',
  LA: '^(\\d{5})$',
  LB: '^(\\d{4}(\\d{4})?)$',
  LC: 0,
  LI: '^(\\d{4})$',
  LK: '^(\\d{5})$',
  LR: '^(\\d{4})$',
  LS: '^(\\d{3})$',
  LT: '^(?:LT)*(\\d{5})$',
  LU: '^(\\d{4})$',
  LV: '^[L]{1}[V]{1}[-]([0-9]){4}$',
  LY: '^(\\d{5})$',
  MA: '^(\\d{5})$',
  MC: '^(\\d{5})$',
  MD: '^(?:MD)*(\\d{4})$',
  ME: '^(\\d{5})$',
  MF: '^(\\d{5})$',
  MG: '^(\\d{3})$',
  MH: '^((969)[6-7][0-9])$',
  MK: '^(\\d{4})$',
  ML: 0,
  MM: '^(\\d{5})$',
  MN: '^(\\d{5})$',
  MO: 0,
  MP: '^(\\d{5})$',
  MQ: '^(\\d{5})$',
  MR: 0,
  MS: 0,
  MT: '^[A-Z]{3} [0-9]{4}$',
  MU: '^([0-9A-Z]\\d{4})$',
  MV: '^(\\d{5})$',
  MW: 0,
  MX: '^(\\d{5})$',
  MY: '^(\\d{5})$',
  MZ: '^(\\d{4})$',
  NA: 0,
  NC: '^(\\d{5})$',
  NE: '^(\\d{4})$',
  NF: '^(2899)$',
  NG: '^(\\d{6})$',
  NI: '^(\\d{5})$',
  NL: '[0-9]{4} [A-Z]{2}',
  NO: '^(\\d{4})$',
  NP: '^(\\d{5})$',
  NR: 0,
  NU: 0,
  NZ: '^(\\d{4})$',
  OM: '^(\\d{3})$',
  PA: 0,
  PE: '^(\\d{5})$',
  PF: '^((987)\\d{2})$',
  PG: '^(\\d{3})$',
  PH: '^(\\d{4})$',
  PK: '^(\\d{5})$',
  PL: '^[0-9]{2}[-]([0-9]){3}$',
  PM: '^(\\d{5})$',
  PN: '^(PCR9 1ZZ)$',
  PR: '^\\d{5}((-|\\s)?\\d{4})?$',
  PS: '^(\\d{5})$',
  PT: '^\\d{4}((-|\\s)?\\d{3})?$',
  PW: '^(96940)$',
  PY: '^(\\d{4})$',
  QA: 0,
  RE: '[9]{1}[7|8][4|7|8]\\d{2}',
  RO: '^(\\d{6})$',
  RS: '^(\\d{5})$',
  RU: '^(\\d{6})$',
  RW: 0,
  SA: '^(\\d{5})$',
  SB: 0,
  SC: 0,
  SD: '^(\\d{5})$',
  SE: '^(\\d{3} \\d{2})$',
  SG: '^(\\d{6})$',
  SH: '^[A-Z]{4} (1ZZ)$',
  SI: '^(\\d{4})$',
  SJ: '^(\\d{4})$',
  SK: '^(\\d{3} \\d{2})$',
  SL: 0,
  SM: '^(4789\\d)$',
  SN: '^(\\d{5})$',
  SO: 0,
  SR: 0,
  SS: '^(\\d{5})$',
  ST: 0,
  SV: '^(01101)$',
  SX: '^(0000 AA)$',
  SY: 0,
  SZ: '^([A-Z]\\d{3})$',
  TC: '^(TKCA 1ZZ)$',
  TD: '^(\\d{5})$',
  TF: '^((984)\\d{2})$',
  TG: 0,
  TH: '^(\\d{5})$',
  TJ: '^(\\d{3})$',
  TK: 0,
  TL: 0,
  TM: '^(\\d{6})$',
  TN: '^(\\d{4})$',
  TO: 0,
  TR: '^(\\d{5})$',
  TT: 0,
  TV: 0,
  TW: '^(\\d{3} \\d{2})|(\\d{3})$',
  TZ: 0,
  UA: '^(\\d{5})$',
  UG: 0,
  US: '^\\d{5}([\\-]\\d{4})?$',
  UY: '^(\\d{5})$',
  UZ: '^(\\d{6})$',
  VA: -120,
  VC: '(VC)(\\d{4})',
  VE: '^(\\d{4})$',
  VG: '^(VG11)[0-6][0]$',
  VI: '^(\\d{5})$',
  VN: '^(\\d{6})$',
  VU: 0,
  WF: 0,
  WS: 0,
  XK: 0,
  YE: 0,
  YT: '^(\\d{5})$',
  ZA: '^(\\d{4})$',
  ZM: '^(\\d{5})$',
  ZW: 0
}
