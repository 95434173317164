// the global requires here are for svgs - ideally we put them in S3, but it's ok for now
/* eslint-disable global-require */
import { COLORS } from 'COLORS'
import { css } from 'styled-components'

const boxShadow = `0 2px 6px 0 ${COLORS.blackOpacity20}`

export const ScrollShadow = css`
  background: linear-gradient(rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0)) 0%
      0% / 100% 30px no-repeat local,
    linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255) 70%) 0px 100% /
      100% 30px local,
    radial-gradient(
        farthest-side at 50% 0px,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0% 0% / 100% 15px scroll,
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0px 100% / 100% 15px scroll;
  background-repeat: no-repeat;
`

const h4 = {
  fontSize: '13px',
  fontWeight: '500',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.38,
  letterSpacing: 'normal',
  color: COLORS.grey900
}

export const NOTIFICATION_STYLE = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: '10px 5px 5px 0',
      backgroundColor: COLORS.white,
      boxShadow,
      WebkitBoxShadow: boxShadow,
      MozBoxShadow: boxShadow,
      color: COLORS.grey900,
      padding: '10px 10px 10px 45px',
      fontSize: '12px',
      borderWidth: '0 0 0 5px',
      borderStyle: 'solid',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '15px 10px',
      zIndex: 101
    },
    success: {
      // Applied only to the success notification item
      borderColor: COLORS.green500,
      backgroundImage: `url("${require('../components/common/icons/svg/notification-success.svg')}")`
    },
    error: {
      // Applied only to the success notification item
      borderColor: COLORS.red500,
      backgroundImage: `url("${require('../components/common/icons/svg/notification-error.svg')}")`
    },
    info: {
      // Applied only to the success notification item
      borderColor: COLORS.grey700,
      backgroundImage: `url("${require('../components/common/icons/svg/notification-info.svg')}")`
    },
    warning: {
      // Applied only to the warning notification item
      borderColor: COLORS.yellow500,
      backgroundImage: `url("${require('../components/common/icons/svg/notification-warning.svg')}")`
    }
  },
  Title: {
    DefaultStyle: h4
  },
  Dismiss: {
    DefaultStyle: {
      content: `url("${require('../components/common/icons/svg/circle-close.svg')}")`,
      backgroundColor: COLORS.transparent,
      color: COLORS.transparent,
      top: '10px',
      right: '10px',
      width: '15px',
      height: '15px'
    }
  },
  Action: {
    DefaultStyle: {
      cursor: 'pointer'
    },
    success: {
      border: `1px solid ${COLORS.grey900}`,
      backgroundColor: COLORS.white,
      color: COLORS.grey900,
      fontSize: '11px',
      fontFamily: '"Inter", sans-serif',
      fontWeight: 500
    },
    error: {
      backgroundColor: COLORS.red500,
      color: COLORS.white
    },
    info: {
      backgroundColor: COLORS.grey700,
      color: COLORS.white
    }
  }
}

export const DEFAULT_STICKY_STYLE = {
  stickyStyle: {
    transform: 'translateY(45px)',
    zIndex: 2
  },
  topOffset: -45,
  bottomOffset: 45
}

export const NAVBAR_STYLE = {
  width: '250px',
  collapsedWidth: '52px'
}
