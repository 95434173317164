export const PAGE_SIZE_OPTIONS = [
  {
    value: 25,
    name: '25'
  },
  {
    value: 50,
    name: '50'
  },
  {
    value: 100,
    name: '100'
  }
]

export const BLANK_ROW_ID = 'blank'

export const EMPTY_MARKER = '-'
