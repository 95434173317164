import React from 'react'
import { Trans } from 'react-i18next'
import { Icon } from '@junglescout/edna'

import { t } from 'services/i18n'
import { COLORS } from 'COLORS'
import {
  SALES_ANALYTICS_URLS,
  KEYWORD_SCOUT_URL,
  DASHBOARD_URL
} from 'constants/routes'
import { shuffleArray } from 'helpers/arrays'

import { ToggleCheckmarkIcon } from 'icons/ToggleCheckmarkIcon/ToggleCheckmarkIcon'
import { PencilFilledIcon } from 'icons/PencilFilledIcon/PencilFilledIcon'

export const SELLER_PURPOSE_OPTIONS = {
  findProduct: 'find_product',
  launchProduct: 'launch_product',
  moreProducts: 'more_products',
  optimizeBusiness: 'optimize_business',
  other: 'other'
}

export const WELCOME_STEP_QUESTIONS = {
  isSeller: {
    title: t(
      'setup_wizard:WelcomeStep.isSellerQuestion.title',
      'Do you have an active Professional Amazon Seller Central account?'
    ),
    subtitle: (
      <Trans i18nKey="setup_wizard:WelcomeStep.isSellerQuestion.subtitle">
        Amazon offers two types of seller accounts: individual (free) and
        professional (requires a monthly subscription).
      </Trans>
    ),
    options: [
      {
        label: <Trans i18nKey="generic:Yes">Yes</Trans>,
        value: 'yes'
      },
      {
        label: <Trans i18nKey="generic:No">No</Trans>,
        value: 'no'
      }
    ]
  },
  sellerPurpose: {
    title: (
      <Trans i18nKey="setup_wizard:WelcomeStep.sellerPurposeQuestion.title">
        What are you here to do?
      </Trans>
    )
  }
}

export const getSellerPurposeOptions = () => {
  const options = [
    {
      label: t(
        'setup_wizard:WelcomeStep.sellerPurposeQuestion.options.findProduct',
        'Find my first product to sell'
      ),
      value: SELLER_PURPOSE_OPTIONS.findProduct
    },
    {
      label: t(
        'setup_wizard:WelcomeStep.sellerPurposeQuestion.options.launchProduct',
        'Launch my product on Amazon'
      ),
      value: SELLER_PURPOSE_OPTIONS.launchProduct
    },
    {
      label: t(
        'setup_wizard:WelcomeStep.sellerPurposeQuestion.options.moreProducts',
        'Find more products to sell'
      ),
      value: SELLER_PURPOSE_OPTIONS.moreProducts
    },
    {
      label: t(
        'setup_wizard:WelcomeStep.sellerPurposeQuestion.options.optimizePerformance',
        'Optimize my business performance'
      ),
      value: SELLER_PURPOSE_OPTIONS.optimizeBusiness
    }
  ]

  const shuffledOptions = shuffleArray(options)
  shuffledOptions.push({
    label: t('generic:Other', 'Other'),
    value: SELLER_PURPOSE_OPTIONS.other
  })

  return shuffledOptions
}

export const SETUP_WIZARD_STEP_INDEXES = {
  welcome: 0,
  mwSync: 1,
  reviewAutomation: 2,
  extension: 3,
  completion: 4
}

export const SETUP_WIZARD_LOCAL_STORAGE_KEY = 'SETUP_WIZARD_DATA'

export const FEATURE_HIGHLIGHTS = [
  {
    icon: <Icon name="AMAZON_CIRCLE" color={COLORS.primary} />,
    title: (
      <Trans i18nKey="setup_wizard:ReviewAutomationStep.Features.AmazonApproved.title">
        Amazon ToS approved solution
      </Trans>
    ),
    description: (
      <Trans i18nKey="setup_wizard:ReviewAutomationStep.Features.AmazonApproved.description">
        Have peace of mind and keep within Amazon’s Terms of Service while
        getting more product reviews.
      </Trans>
    )
  },
  {
    icon: <ToggleCheckmarkIcon />,
    title: (
      <Trans i18nKey="setup_wizard:ReviewAutomationStep.Features.SetItForgetIt.title">
        Set It & Forget It
      </Trans>
    ),
    description: (
      <Trans i18nKey="setup_wizard:ReviewAutomationStep.Features.SetItForgetIt.description">
        Eliminate the tedious task of manually requesting reviews on Seller
        Central so you can invest time back into your business.
      </Trans>
    )
  },
  {
    icon: <PencilFilledIcon />,
    title: (
      <Trans i18nKey="setup_wizard:ReviewAutomationStep.Features.CustomizableReviews.title">
        Customizable review requests
      </Trans>
    ),
    description: (
      <Trans i18nKey="setup_wizard:ReviewAutomationStep.Features.CustomizableReviews.description">
        Control which of your SKUs or orders receive review requests and even
        set a delay for when these requests are sent.
      </Trans>
    )
  }
]

export const COMPLETED_STEP_INFO = {
  [SELLER_PURPOSE_OPTIONS.findProduct]: {
    subtitle: (
      <Trans i18nKey="setup_wizard:CompletionStep.FindProduct.subtitle">
        Join other new sellers who have found their first product idea with our
        expert-guided videos. It's the surefire way to conquer the jungle.
        <br /> <br /> Happy Scouting!
      </Trans>
    ),
    video: 'wistia_async_y1rl3ghal1',
    academyLink: '/academy/4',
    redirectLink: DASHBOARD_URL
  },
  [SELLER_PURPOSE_OPTIONS.launchProduct]: {
    subtitle: (
      <Trans i18nKey="setup_wizard:CompletionStep.LaunchProduct.subtitle">
        Join other new sellers who have launched their first product idea with
        our expert-guided videos. It's the surefire way to conquer the jungle.
        <br /> <br /> Happy Scouting!
      </Trans>
    ),
    video: 'wistia_async_1m4dkv46xb',
    academyLink: '/academy/28',
    redirectLink: KEYWORD_SCOUT_URL
  },
  [SELLER_PURPOSE_OPTIONS.moreProducts]: {
    subtitle: (
      <Trans i18nKey="setup_wizard:CompletionStep.MoreProducts.subtitle">
        Join other sellers who have found more product ideas with our
        expert-guided videos. It's the surefire way to conquer the jungle.
        <br /> <br /> Happy Scouting!
      </Trans>
    ),
    video: 'wistia_async_tr0tm240du',
    academyLink: '/academy/4',
    redirectLink: DASHBOARD_URL
  },
  [SELLER_PURPOSE_OPTIONS.optimizeBusiness]: {
    subtitle: (
      <Trans i18nKey="setup_wizard:CompletionStep.OptimizeBusiness.subtitle">
        Join other sellers who have improved their business performance with our
        expert-guided videos. It's the surefire way to conquer the jungle.
        <br /> <br /> Happy Scouting!
      </Trans>
    ),
    video: 'wistia_async_dfl8a95zpj',
    academyLink: '/academy/26',
    redirectLink: SALES_ANALYTICS_URLS.profitOverview
  },
  [SELLER_PURPOSE_OPTIONS.other]: {
    subtitle: (
      <Trans i18nKey="setup_wizard:CompletionStep.Other.subtitle">
        Join other sellers who have improved their business performance with our
        expert-guided videos. It's the surefire way to conquer the jungle.
        <br /> <br /> Happy Scouting!
      </Trans>
    ),
    video: '',
    academyLink: '/academy/3',
    redirectLink: DASHBOARD_URL
  }
}
